<template>
  <div
    style="max-width: 1200px;"
    class="mx-auto p-2 "
    dir="ltr"
  >
    <b-card
      class="p-3"
      style="text-align: left;"
      v-html="text"
    />
  </div>
</template>

<script >
import DescriptionApis from '@/services/apis/description'

const description = new DescriptionApis()
export default {
  data() {
    return {
      text: '',
    }
  },
  mounted() {
    description.get({ name: 'agreement-register' }).then(res => {
      this.text = res.data.results.value
    })
  },
}

</script>
